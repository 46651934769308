@import '~normalize.css/normalize.css';
@import '~bootstrap/scss/bootstrap';

$sitefont: "Verdana", sans-serif;
$bg-color: #ffc200;
$fg-color: black;
$red: #ad0000;
$primary-color: $red;

html, body, #root, div, p, span, h1, h2, h3, h4, h5, ul, li {
  font-family: $sitefont;
  color: $fg-color;
  background-color: $bg-color;
}

#root {
  background-color: transparent;
}

html, body, #root {
  height: 100vh;
  width: 100%;
  //overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

a, a:hover, a:visited, a:active, a:visited {
  color: $fg-color;
  text-decoration: underline;
}

.container {
  background-color: $bg-color;
}

.text {
  color: $fg-color;
}

.no-bg {
  background-color: transparent;

  div, span, input, p {
    background-color: transparent;
  }
}

.react-select {
  background-color: #fff;
}

.react-select__placeholder {
  color: #495057 !important;
}

.react-select__control {
  border: 1px solid #ced4da !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
}

.react-select__menu,
.react-select__listbox,
.react-select__menu-notice--no-options {

  background-color: #ddd !important;
  color: black !important;

  span {
    color: black !important;
  }
}

.react-select__menu-notice--no-options {
  font-weight: bold;
}

.react-datepicker__month-container,
.react-datepicker__month,
.react-datepicker__week,
.react-datepicker__day,
.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker__current-year,
.react-datepicker__header__dropdown,
.react-datepicker__day-names,
.react-datepicker__day-name,
.react-datepicker__day:hover,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: white;
  color: $red;
}

.react-datepicker__navigation:hover *::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: white;
}

.react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
  background-color: transparent;
}

.btn-danger {
  background-color: $red;
  color: white;
  font-weight: bold;
}

@import '~loaders.css/src/animations/line-scale.scss';
@import '~loaders.css/src/animations/ball-pulse.scss';
@import '~loaders.css/src/animations/ball-triangle-trace.scss';
@import '~loaders.css/src/animations/ball-pulse-rise.scss';

.loader {

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  .loader-hidden {
    display: none;
  }
  .loader-active {
    display: block;
  }
}

/**
grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
*/

// extra-small devices (landscape phones, 576px and up)
@media (min-width: 0) {
  .container {
    width: 100%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .container {
    width: 100%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container {
    width: 80%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .container {
    width: 50%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: unset;
    -moz-appearance: unset;
    appearance: unset;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .container {
    width: 40%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: unset;
    -moz-appearance: unset;
    appearance: unset;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .container {
    width: 30%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: unset;
    -moz-appearance: unset;
    appearance: unset;
  }
}

.text-small {
  font-size: 12px;
}

.form-control {
  color: black;
}